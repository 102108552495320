<template>
  <div class="d-flex flex-column align-items-center mt-5">
    <input v-model='article_name' id='article-name' type="text" class="mt-3 mb-3" placeholder="Название статьи"/>
    <div class="toolbar w-100 mb-3">
      <button @click="toggleBold" :class="{ active: isActive('bold') }">Bold</button>
      <button @click="toggleItalic" :class="{ active: isActive('italic') }">Italic</button>
      <button @click="toggleUnderline" :class="{ active: isActive('underline') }">Underline</button>
      <button @click="toggleBulletList" :class="{ active: isActive('bulletList') }">Bullet List</button>
      <button @click="toggleOrderedList" :class="{ active: isActive('orderedList') }">Ordered List</button>
      <select v-model="currentHeading" @change="setHeading" class="form-select w-25">
        <option value="paragraph">Paragraph</option>
        <option value="1">Heading 1</option>
        <option value="2">Heading 2</option>
        <option value="3">Heading 3</option>
        <option value="4">Heading 4</option>
        <option value="5">Heading 5</option>
        <option value="6">Heading 6</option>
      </select>
    </div>
    <input type="file" accept="image/*" @change="uploadImage" />
    <editor-content :editor="editor" class="custom-editor mt-3" />
    <button @click="saveContent" class="btn btn-primary mt-5">Save Content</button>
  </div>
</template>

<script>
import axios from '@/assets/js/axios.js'

import { ref, watch, onMounted } from 'vue'

import { useRouter, useRoute } from 'vue-router'
import { EditorContent, Editor } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import ListItem from '@tiptap/extension-list-item'
import History from '@tiptap/extension-history'
import Image from '@tiptap/extension-image'

export default {
  components: {
    EditorContent,
  },
  setup() {
    const route = useRoute() // Access the current route
    const router = useRouter() // For navigation if needed
    
    const editorContent = ref('<p>Initial content</p>')
    const article_slug = ref('')
    const article_name = ref('')

    
    const currentHeading = ref('paragraph')
    
    const editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        Heading.configure({
          levels: [1, 2, 3, 4, 5, 6],
        }),
        Bold,
        Italic,
        Underline,
        BulletList,
        OrderedList,
        ListItem,
        History,
        Image
      ],
      content: editorContent.value,
      onSelectionUpdate: ({ editor }) => {
        // Update currentHeading when the selection changes
        const level = [1, 2, 3, 4, 5, 6].find((l) => editor.isActive('heading', { level: l }))
        currentHeading.value = level ? String(level) : 'paragraph'
      },
    });
    
    

    const saveContent = async () => {
      const htmlContent = editor.getHTML()
      const jsonContent = editor.getJSON()
      const articleName = document.getElementById('article-name').value
      
      try {
        const response = await axios.post('/api/v1/articles/save_article_content', {
          article_html: htmlContent,
          article_json: jsonContent,
          article_name: articleName,
          article_slug: article_slug.value
        });
        article_slug.value = response.data
        console.log('Content saved successfully:', article_slug.value)
        router.push(`/articles/edit/${article_slug.value}`)
      } catch (error) {
        console.error('Error saving content:', error.response?.data || error.message)
      }
    };

    const uploadImage = async (event) => {
      const file = event.target.files[0]
      if (!file) return

      try {
        const formData = new FormData()
        formData.append('image', file)

        const response = await axios.post('/api/v1/articles/upload_article_image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const imageUrl = response.data.image_path
        editor.chain().focus().setImage({ src: imageUrl }).run()
      } catch (error) {
        console.error('Error uploading image:', error.response?.data || error.message)
      }
    };

    const fetchArticleContent = async () => {
      if (!article_slug.value) return;

      try {
        const response = await axios.get(`/api/v1/articles/article_edit/${article_slug.value}`);
        const article = response.data;

        console.log(article)
        if (article && article.json.content) {
          // Update editor content with fetched article content
          editor.commands.setContent(article.json.content)
          article_name.value = article.name
        }
      } catch (error) {
        console.error('Error fetching article content:', error)
      }
    };

    // Extract the slug from the last part of the URL after '/edit/'
    onMounted(() => {
      const path = route.path; // Get the full path
      const editPrefix = '/articles/edit/'; // Define the prefix to look for
      if (path.startsWith(editPrefix)) {
        article_slug.value = path.slice(editPrefix.length); // Extract slug
      }
      fetchArticleContent()
    });

    const toggleBold = () => {
      editor.chain().focus().toggleBold().run()
    };

    const toggleItalic = () => {
      editor.chain().focus().toggleItalic().run()
    };

    const toggleUnderline = () => {
      editor.chain().focus().toggleUnderline().run()
    };

    const toggleBulletList = () => {
      editor.chain().focus().toggleBulletList().run()
    };

    const toggleOrderedList = () => {
      editor.chain().focus().toggleOrderedList().run()
    };

    const setHeading = () => {
      if (currentHeading.value === 'paragraph') {
        editor.chain().focus().setParagraph().run()
      } else {
        editor.chain().focus().toggleHeading({ level: parseInt(currentHeading.value) }).run()
      }
    }

    const isActive = (type) => {
      return editor && editor.isActive(type)
    };

    return {
      editor,
      article_name,
      toggleBold,
      toggleItalic,
      toggleUnderline,
      toggleBulletList,
      toggleOrderedList,
      isActive,
      saveContent,
      setHeading,
      currentHeading,
      uploadImage
    };
  },
  unmounted() {
    editor.destroy()
  },
};
</script>

<style>
/* Editor container styles */
.custom-editor {
  width: 1000px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
  overflow: hidden;
  display: inline-block;

}

.custom-editor .ProseMirror {
  font-size: 16px;
  line-height: 1.5;
  padding: 10px;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  min-height: 200px; /* Set a minimum height */
  padding-bottom: 48px;
}

/* Focus styles for editor */
.custom-editor .ProseMirror:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 2px rgba(128, 189, 255, 0.25);
}

/* Custom styles for text */
.custom-editor .ProseMirror h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.custom-editor .ProseMirror p {
  margin: 0 0 10px;
}

.custom-editor .ProseMirror a {
  color: #007bff;
  text-decoration: none;
}

.custom-editor .ProseMirror a:hover {
  text-decoration: underline;
}

.toolbar{
  display: flex;
  gap: 12px;
}

.toolbar button {
  padding: 8px 12px;
  margin: 0 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

/* Hover state for buttons */
.toolbar button:hover {
  background-color: #e6e6e6;
}

/* Active state for buttons */
.toolbar button.active {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
}

/* Disabled buttons */
.toolbar button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

</style>