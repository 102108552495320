<template>
    <h5 class="mt-5">Соблюдение требований законодательства</h5>
    <table class="table report-table text-wrap" style="border-top:1px solid lightgray">
    
        <tbody >
        <tr>
            <td style="width:60%">Название компании</td>
            <td>
                <div v-if=my_website_report.company_name style="color:green">{{ my_website_report.company_name }}</div>
                <div v-else style="color:red">не найдено</div>
            </td>
            <td></td>
        </tr>
            <tr>
            <td>ИНН</td>
            <td>
                <div v-if=my_website_report.inn style="color:green">{{ my_website_report.inn }}</div>
                <div v-else style="color:red">не найдено</div>
            </td>
            <td>
                <div v-if="!my_website_report.inn" style="color:red">
                    предписание (после — штраф до 20к)
                    <div class="law-link"></div>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <div v-if=my_website_report.ogrn>ОГРН</div>
                <div v-else-if=my_website_report.ogrnip style="color:green">ОГРНИП</div>
                <div v-else>ОГРН/ОГРНИП</div>
            </td>
            <td>
                <div v-if=my_website_report.ogrn style="color:green">{{ my_website_report.ogrn }}</div>
                <div v-else-if=my_website_report.ogrnip style="color:green">{{ my_website_report.ogrnip }}</div>
                <div v-else style="color:red">не найден</div>
            </td>
            <td>
                <div v-if="!my_website_report.ogrn && !my_website_report.ogrnip" style="color:red">
                    предписание (после — штраф до 20к)
                    <div class="law-link"></div>
                </div>
            </td>
        </tr>
        <tr>
            <td>Политика обработки персданных</td>
            <td>
                <div v-if=my_website_report.has_policy style="color:green">скорее да</div>
                <div v-else style="color:red">не найдена</div>
            </td>
            <td><div v-if=my_website_report.has_policy></div></td>
        </tr>
        <tr>
            <td>Предупреждение о куках</td>
            <td>
                <div v-if=my_website_report.has_cookie_warning style="color:green">скорее да</div>
                <div v-else style="color:red">не найдено</div>
            </td>
            <td><div v-if=!my_website_report.has_cookie_warning style="color:red">штраф 60-100к рублей</div></td>
        </tr>
        <tr v-if=my_website_report.needs_oferta>
            <td>Оферта</td>
            <td>
                <div v-if=my_website_report.has_oferta style="color:green">скорее да</div>
                <div v-else style="color:red">не найдена</div>
            </td>
            <td><div v-if=!my_website_report.has_oferta style="color:red">штраф 15-40к рублей</div></td>
        </tr>
        <tr v-if=my_website_report.has_oferta>
            <td>Акцепт</td>
            <td>
                <div v-if=my_website_report.has_oferta_accept style="color:green">скорее да</div>
                <div v-else style="color:red">не найден</div>
            </td>
            <td><div v-if=!my_website_report.has_oferta_accept style="color:red">
                клиент может потребовать вернуть деньги
            </div>
        </td>
        </tr>
    </tbody>
    </table>
</template>

<script>

export default {
    name: 'LegalTable',
    props: {
        my_website_report: {
        }
    }
}
</script>

<!-- <style scoped>
.table td:nth-child(1), .table th:nth-child(1) {
    width: 40% !important; /* Set the desired width */
  }
</style> -->