<template>
    <Navbar />
    <div class="mt-5 m-auto" style="width: 700px; max-width: 95%; position: relative;">
      <h1>Выберите свою компанию из списка</h1>
      <div>Ее данные будут использованы для составления отчетов (СВОТ, ПЕСТ и тд)</div>
  
      
      <div class="mt-5">
        <form>
          <div v-for="(company, ) in companies" :key="company.uuid" class="radio-item">
            <input
              type="radio"
              :id="'company_' + company.uuid"
              :value="company.uuid"
              v-model="selectedCompany"
            />
            <label :for="'company_' + company.uuid" class="ms-3 radio-label">
              {{ company.name_ya }} - {{ company.address }}
            </label>
          </div>
          <div class="radio-item">
            <input
              type="radio"
              id="none"
              value=""
              v-model="selectedCompany"
            />
            <label for="none" class="ms-3">Моего бизнеса здесь нет (только отчет по конкурентам)</label>
          </div>
        </form>
  
        <div class="mt-3">
          <button id="continue-btn" @click="continue_report_by_address()" class="btn btn-primary" >Подтвердить выбор</button>
          <div v-if="loading">Собираем данные для вашего отчета!</div>
          <div v-if="loading">Дайте нам пару минут...</div>
          <div v-else-if="error">{{ error }}</div>
        </div>
      </div>
    </div>
    <Footer />
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import axios from '@/assets/js/axios.js'
  
  
  export default {
    name: 'PickMyCompany',
  
    setup() {
      const router = useRouter()
      const route = useRoute()
      const reportUuid = route.params.uuid
      const companies = ref([]);
      const selectedCompany = ref('')
      const loading = ref(false)
      const error = ref(null)
      // Fetch companies from the API when the component is mounted
      onMounted(async () => {
        console.log(reportUuid)
        try {
          const response = await axios.post(
            `/api/v1/reports/get_competitors_by_report_uuid`,
            { report_uuid: reportUuid }
          );
          companies.value = response.data;
        } catch (err) {
          error.value = 'Ошибка загрузки списка компаний'
          console.error(err)
        } finally {
          loading.value = false
        }
      });
      const continue_report_by_address = async () => {
    try {
        const btn = document.getElementById('continue-btn')
        btn.hidden = true
        loading.value = true

        // Start the task
        const response = await axios.post('/api/v1/reports/continue_report_by_address', {
            my_company_uuid: selectedCompany.value,
            report_uuid: reportUuid,
        });

        const taskId = response.data.task_id
        console.log('Task started:', taskId)

        // Listen for SSE notifications
        const eventSource = new EventSource(`${axios.defaults.baseURL}api/v1/reports/notifications`)
        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data)

            if (data.task_id === taskId) {
                if (data.uuid) {
                    console.log('Report generated:', data.uuid)
                    eventSource.close()
                    router.push(`/report/${data.uuid}`)
                } else if (data.error) {
                    console.error('Task failed:', data.error)
                    eventSource.close()
                    error.value = 'Ошибка при создании отчета!'
                }
            }
        };
    } catch (error) {
        console.error('Error starting report:', error)
        error.value = 'Ошибка!'
    }
};

  
      return {
        companies,
        selectedCompany,
        loading,
        error,
        continue_report_by_address
      };
    }
  };
  </script>
  

<style scoped>
  .radio-item {
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
  }
  .radio-label{
    display: inline-block; /* Ensures label behaves more like a block */
    white-space: normal; /* Allow text wrapping */
    overflow-wrap: break-word;
  }
  </style>