<template>
  <div class="mt-5 m-auto" style="width: 700px; max-width: 95%; position:relative;">
    <h1>Моментальный обзор вашего бизнеса</h1>
    <div>Узнайте, как повысить привлекательность своего бизнеса для клиентов.</div>
    <div class="mt-5">
      <h3>Для кого это?</h3>
      <ul>
        <li>Руководители малого и среднего бизнеса в сфере B2C</li>
        <li>Подходящие сферы: рестораны, медицинские центры, сервисы и др.</li>
      </ul>
    </div>
    <h4>Как это работает?</h4>
    <ol>
      <li>Вы вводите адрес, где находится ваш бизнес или где вы планируете открыть его. А также направление деятельности.</li>
      <li>Мы находим подходящие компании в радиусе километра</li>
      <li>Вы выбираете из них свою</li>
      <li>Мы собираем всю доступную в сети информацию о вашей компании и о конкурентах.</li>
      <li>Вы получаете отчет, который включает СВОТ, анализ сайта, список конкурентов и способы их обойти в виде набора Уникальных Торговых Предложений</li>
    </ol>
    <div class="mt-5">
      <form class="d-flex flex-column" style="gap:18px">
        <div class="d-flex flex-column">
          <label for="address">Адрес:</label>
          <input
            class="form-control"
            type="text"
            id="address"
            v-model="address"
            name="address"
            required
            placeholder="Введите адрес"
          />
        </div>
        <div class="d-flex flex-column">
          <label for="business_type">Род деятельности</label>
          <input
            class="form-control"
            type="text"
            id="business_type"
            v-model="businessType"
            name="business_type"
            required
            placeholder="Ресторан, стоматология, фитнес-клуб..."
          />        
        </div>
        
        <!-- <button type="button" class="btn btn-primary" @click="create_new_report()">Заказать отчет</button> -->
        <button type="button" class="btn btn-primary" @click="create_report_by_address">Заказать отчет — 2000 рублей</button>
        <router-link to="/report/f79ab43a-9176-43be-bf11-82398c286840" class="w-100">
          <button type="button" class="btn btn-outline-secondary w-100">Пример отчета</button>
        </router-link>
      </form>
    </div>
    <div class="mt-4">
      <p></p>
    </div>
    
  </div>

</template>
  
<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from '@/assets/js/axios.js'
import { useReportsStore } from '@/store/modules/reports'


export default {
  name: 'Landing',
  components: {  },
  
  setup() {
    const router = useRouter()
    const isLoading = ref(false)
    const address = ref('')
    const businessType = ref('')
    const reportsStore = useReportsStore()
    
    const create_report_by_address = async () => {
      
      try {
        isLoading.value = true;
        const response = await axios.post('api/v1/reports/create_report_by_address', {
          address: address.value,
          business_type: businessType.value
        })
        .then(response => {
          reportsStore.setReportUuid(response.data);
        })
        
        router.push(`/payment`)
      } catch (error) {
        console.error('Error fetching report UUID:', error)
      } finally {
        isLoading.value = false
      }
    };


    return {
      isLoading,
      address,
      businessType,
      create_report_by_address
    };
  },
};
</script>