<template>
    <div class="mt-5 d-flex flex-column align-items-center main-container" style="width:800px; max-width: 95%; margin:auto; margin-bottom:100px"> 
      <h1 class="mb-4" v-if="article">{{ article.name }}</h1>
    <div v-if="article" v-html="article.html"></div>
    </div>
</template>


<script>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import axios from '@/assets/js/axios.js'

export default {
    name: 'Article',
    components: { },
    setup() {
        const route = useRoute()
        const article = ref(null)
        const loading = ref(true)
        const error = ref(false)

        const fetchArticleData = async () => {
        try {
            console.log('!!!')
            const { slug } = route.params
            const response = await axios.get(`/api/v1/articles/article/${slug}`)
            
            console.log('!', response.data)
            article.value = response.data
        } catch (err) {
            error.value = err.message || 'Ошибка при загрузке данных'
        } finally {
            loading.value = false;
        }
        }

        onMounted(fetchArticleData);
        
        return {
        article,
        loading,
        error,
    


        };
    },
};
</script>

<style scoped>
.main-container {
    font-family: 'Montserrat';
    font-size: clamp(15px, 3vw, 18px) !important;
}


li:not(:last-child) { 
   margin-bottom: 12px;  
}

.usp-block li{
    padding-left: 16px;
    line-height: 172%;
}
</style>