<template>
    <h5 class="mt-5">Контакты на сайте</h5>
    <table class="table report-table text-wrap">
    <tbody>
        <tr>
        <td style="width:60%">Адрес</td>
        <td>
            <div v-if=my_website_report.address style="color:green">{{ my_website_report.address }}</div>
            <div v-else style="color:red">не найден</div>
        </td>
        </tr>
        <tr>
        <td>Телефон</td>
        <td>
            <div v-if=my_website_report.phone style="color:green">{{ my_website_report.phone }}</div>
            <div v-else style="color:red">не найден</div>
        </td>
        </tr>
        <tr>
        <td>Почта</td>
        <td>
            <div v-if=my_website_report.email style="color:green">{{ my_website_report.email }}</div>
            <div v-else style="color:red">не найдена</div>
        </td>
        </tr>
        <tr>
        <td>Телеграм</td>
        <td>
            <div v-if=my_website_report.telegram style="color:green">{{ my_website_report.telegram }}</div>
            <div v-else style="color:red">не найден</div>
        </td>
        </tr>
        <tr>
        <td>Вотсап</td>
        <td>
            <div v-if=my_website_report.whatsapp style="color:green">{{ my_website_report.whatsapp }}</div>
            <div v-else style="color:red">не найден</div>
        </td>
        </tr>
    </tbody>
    </table>
</template>

<script>

export default {
    name: 'ContactsTable',
    props: {
        my_website_report: {
        }
    }
}
</script>
